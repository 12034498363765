import { SearchFilters } from '../../search/schemas'
import { PriceRange } from '../../search/types'

interface QueryPart {
  max_price?: number
  min_price?: number
}

export function toQuery({ priceRange }: SearchFilters): QueryPart | {} {
  return {
    min_price: priceRange?.min && getPriceParam(priceRange.min),
    max_price: priceRange?.max && getPriceParam(priceRange.max),
  }
}

export function fromQuery({ min_price, max_price }: Partial<QueryPart>): {
  priceRange?: PriceRange
} {
  let min = validateAmountNumber(min_price)
  let max = validateAmountNumber(max_price)

  const enabled = min !== undefined || max !== undefined
  if (!enabled) return {}

  min = min !== undefined ? min : 0
  max = max !== undefined ? max : Infinity

  if (min < max) return { priceRange: { min, max } }
  else return {}
}

function getPriceParam(value: number) {
  return isFinite(value) && value > 0 ? value : undefined
}

function validateAmountNumber(val: any): number | undefined {
  if (isNaN(val)) return
  const valUnchecked = Number(val)
  if (isFinite(valUnchecked) && valUnchecked >= 0) return valUnchecked
  return undefined
}
