import { Container } from 'inversify';
import { interpret } from 'xstate';
import { rootContainer } from '~/container';
import { returnNextBookingMachine } from './nextBookingMachine';
import { NextBookingsRepositoryFetch } from './NextBookingsRepositoryFetch';
import { NextBookingsRepository } from './types';
export var nextBookingContainer = new Container();
nextBookingContainer.parent = rootContainer;
nextBookingContainer.bind(NextBookingsRepository).to(NextBookingsRepositoryFetch);
nextBookingContainer.bind('NextBookingMachine').toDynamicValue(ctx => interpret(returnNextBookingMachine().withConfig({
  services: {
    getNextBooking: () => ctx.container.get(NextBookingsRepository).getNext(useNuxtApp().$route.params.lang || 'en-gb')
  }
})));