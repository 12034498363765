import { SearchFilters } from '../../search/schemas'
import { ArriveDepartIso } from '../../search/types'
import { ensureArray } from '~/utility/array'
import { addDays } from '~/utility/date/relative'

interface QueryPart {
  arrive?: string[] | string
  depart?: string[] | string
  nights?: number
}

const defaultNights = 2

export function toQuery({ dates }: SearchFilters): QueryPart | {} {
  return {
    arrive: dates?.arrive || undefined,
    depart: dates?.depart || undefined,
  }
}

export function fromQuery(query: Partial<QueryPart>): {
  dates?: ArriveDepartIso
} {
  return {
    dates: getRange(query),
  }
}

function getRange(query: QueryPart) {
  if (isArriveDepart(query)) {
    return parseArriveDepart(query)
  } else if (isArriveNights(query)) {
    return parseArriveNights(query)
  } else if (query.arrive && !query.depart) {
    return parseArriveNights({ nights: defaultNights, ...query })
  }
  return undefined
}

function isArriveDepart({ arrive, depart }: QueryPart) {
  return arrive && depart
}

function parseArriveDepart({ arrive, depart }: QueryPart) {
  return {
    arrive: ensureArray(arrive)[0],
    depart: ensureArray(depart)[0],
  }
}

function isArriveNights({ arrive, nights }: QueryPart) {
  return arrive && nights
}

function parseArriveNights(query: QueryPart) {
  const arrive = ensureArray(query.arrive)[0]
  const nights = Number(query.nights)
  const arriveDate: string = arrive
  const departDate: string = addDays(nights, arriveDate)
  const depart = departDate
  return {
    arrive,
    depart,
  }
}
