import { langFallback$Fetch } from '~/lang/utils/langfetch'
import { PendingReview, pendingReviewsSchema } from './schemas'

export class PendingReviewRepositoryFetch {
  async getPending(langCode: string): Promise<PendingReview[]> {
    const response = await langFallback$Fetch(
      langCode,
      '/_/users/user-available-followups/',
    )
    return pendingReviewsSchema.parse(response)
  }
}
