import * as z from 'zod'

export const pendingReviewSchema = z.object({
  campsiteName: z.string(),
  campsiteSlug: z.string(),
  token: z.string(),
})

export type PendingReview = z.infer<typeof pendingReviewSchema>
export const pendingReviewsSchema = pendingReviewSchema.array()
