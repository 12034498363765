import { SearchFilters } from '../../search/schemas'
import { ensureArray } from '~/utility/array'

interface QueryPart {
  type?: string[]
  facet?: string[]
}

export function toQuery({
  facets,
  categoryIds,
}: SearchFilters): QueryPart | {} {
  const type = categoryIds ? categoryIds.map((catId) => `${catId}`) : []
  return {
    type,
    facet: facets,
  }
}

export function fromQuery({ type, facet }: Partial<QueryPart>): {
  categoryIds?: string[]
  facets?: string[]
} {
  return {
    ...(type ? { categoryIds: sanitizeCategoryIds(type) } : {}),
    ...(facet ? { facets: sanitizeFacets(facet) } : {}),
  }
}

function sanitizeCategoryIds(type: string | string[]) {
  return ensureArray(type).map((catId) => catId)
}

function sanitizeFacets(facets: string | string[]) {
  return ensureArray(facets)
}
