import { SearchFilters } from '../../search/schemas'
import { UrlQuery } from '../domain/UrlQuery'
import * as boundsUrlPart from './boundsUrlPart'
import * as campsiteFiltersUrlPart from './campsiteFiltersUrlPart'
import * as datesUrlPart from './datesUrlPart'
import * as geolocationUrlPart from './geolocationUrlPart'
import * as hierarchyUrlPart from './hierarchyUrlPart'
import * as partyUrlPart from './partyUrlPart'
import * as priceRangeUrlPart from './priceRangeUrlPart'

interface UrlPart {
  fromQuery(query: UrlQuery): Partial<SearchFilters>

  toQuery(filters: SearchFilters): UrlQuery
}

const sortUrlPart: UrlPart = {
  fromQuery({ sort, page }) {
    if (page) {
      page = Number(page)
      if (isNaN(page)) page = undefined
    }
    return {
      sort,
      page: page as number | undefined,
    }
  },

  toQuery({ sort, page }) {
    return { sort, page }
  },
}

const placenameUrlPart: UrlPart = {
  fromQuery({ placename, q }) {
    return { placename, q }
  },
  toQuery({ placename, q }) {
    return { placename, q }
  },
}

const limitPart: UrlPart = {
  fromQuery({ limit }) {
    return { limit: typeof limit !== 'undefined' ? Number(limit) : undefined }
  },
  toQuery({ limit }) {
    return { limit: limit ? limit.toString() : undefined }
  },
}

const withinPart: UrlPart = {
  fromQuery({ within }) {
    return {
      within: typeof within !== 'undefined' ? Number(within) : undefined,
    }
  },
  toQuery({ within }) {
    return { within: within ? within.toString() : undefined }
  },
}

export const urlParts: UrlPart[] = [
  sortUrlPart,
  geolocationUrlPart,
  datesUrlPart,
  campsiteFiltersUrlPart,
  partyUrlPart,
  priceRangeUrlPart,
  hierarchyUrlPart,
  boundsUrlPart,
  placenameUrlPart,
  limitPart,
  withinPart,
]
