export const exclusions: Record<string, string[]> = {
  none: [],
  homepage: [
    'lat',
    'lng',
    'placename',
    'within',
    'q',
    'search',
    'sort',
    'bounds',
    'page',
    'facet',
    'min_price',
    'max_price',
    'scroll_to_pitchtype',
  ],
  homepageLink: [
    'bounds',
    'facet',
    'max_price',
    'min_price',
    'page',
    'lat',
    'lng',
    'placename',
    'within',
    'scroll_to_pitchtype',
    'search',
    'sort',
  ],
  newLocation: [
    'lat',
    'lng',
    'placename',
    'q',
    'search',
    'within',
    'sort',
    'bounds',
    'page',
  ],
  newLocationNoSort: [
    'lat',
    'lng',
    'placename',
    'q',
    'search',
    'within',
    'bounds',
    'page',
  ],
  newCategory: ['type', 'lat', 'lng', 'placename', 'q', 'search', 'sort'],
  newCategoryNoSort: [
    'type',
    'lat',
    'lng',
    'placename',
    'q',
    'search',
    'within',
  ],
  campsiteLink: ['lat', 'lng', 'placename', 'within', 'bounds'],
  type: ['type'],
  visibleMarker: ['visibleMarker'],
  // campsite uses this one
  fullMinusType: [
    'lat',
    'lng',
    'placename',
    'q',
    'search',
    'within',
    'sort',
    'bounds',
    // 'facet',
    'min_price',
    'max_price',
    'scroll_to_pitchtype',
    'visibleMarker',
    'page',
  ],
  breadcrumb: [
    'lat',
    'lng',
    'placename',
    'q',
    'search',
    'bounds',
    'scroll_to_pitchtype',
    'page',
    'within',
  ],
  breadcrumbCity: [
    'lat',
    'lng',
    'placename',
    'q',
    'search',
    'bounds',
    'scroll_to_pitchtype',
    'page',
  ],
  breadcrumbCampsites: ['lat', 'lng', 'placename', 'sort'],
  breadcrumbSingleCategory: [
    'type',
    'within',
    'sort',
    'facet',
    'page',
    'visibleMarker',
  ],
  browseAllCategories: ['type', 'page'],
  nearby: ['lat', 'lng', 'placename', 'q', 'bounds'],
  all: [
    'lat',
    'lng',
    'placename',
    'q',
    'search',
    'within',
    'sort',
    'bounds',
    'facet',
    'min_price',
    'max_price',
    'scroll_to_pitchtype',
    'visibleMarker',
    'page',
    'type',
  ],
  booking: [],
  campsite: [
    'page',
    'bounds',
    'lat',
    'lng',
    'within',
    'facet',
    'min_price',
    'max_price',
    'bounds',
    'sort',
  ],
  nearMe: ['placename', 'q', 'search', 'type', 'bounds'],
  paginationControl: [],
  searchDefault: ['page'],
  searchLowResult: ['page'],
  searchMap: ['page'],
  searchWizard: ['page'],
  searchWizardAutosuggestionCampsite: ['sort'],
  breadcrumbCategory: ['page', 'type', 'sort'],
  postCampsiteReview: ['verify'],
  alwaysExcluded: ['review', 'review_posted', 'hierarchyPath', 'centre', 'q'],
}
