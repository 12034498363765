import { assign, createMachine, interpret, Interpreter } from 'xstate'
import sentryCapture from '~/utility/sentryCapture'
import { PendingReviewRepositoryFetch } from './PendingReviewRepositoryFetch'
import { PendingReview } from './schemas'

interface PendingReviewContext {
  reviews: PendingReview[]
  langCode: string
}

export function returnPendingReviewMachine() {
  return createMachine<PendingReviewContext>(
    {
      initial: 'fetching',
      context: { reviews: [], langCode: 'en-gb' },
      states: {
        fetching: {
          invoke: {
            src: 'getPendingReviews',
            onDone: {
              target: 'idle',
              actions: assign({
                reviews: (_, { data }) => data,
              }),
            },
            onError: {
              target: 'idle',
              actions: 'logError',
            },
          },
        },
        idle: {
          on: {
            FETCH_PENDING_REVIEWS: 'fetching',
          },
        },
      },
    },
    {
      services: {
        getPendingReviews: ({ langCode }) => {
          const reviews = new PendingReviewRepositoryFetch()
          return reviews.getPending(langCode)
        },
      },
      actions: {
        logError: (_, event: any) => {
          if (!event || !event.data || !event.data.response) return
          const response = event.data.response
          if (response && (response.status < 500 || response.statusCode < 500))
            return
          event.data.name = `pendingReviewsMachine ${event.type} error`
          sentryCapture(event.data)
        },
      },
    },
  )
}

export type PendingReviewMachineInterpreter = Interpreter<PendingReviewContext>

export function getPendingReviewsMachine(langCode: string) {
  return interpret(
    returnPendingReviewMachine().withContext({ reviews: [], langCode }),
  )
}
