import { SearchFilters, SearchParty } from '../../search/schemas'
import { childAgeCSVStringToNumberArray } from '~/utility/child-ages/child-ages'

interface QueryPart {
  adults?: string
  child_ages?: string[] | string
  children?: string
}

export function toQuery({ party }: SearchFilters): QueryPart | {} {
  if (!party) return {}
  const query: QueryPart = {}
  if (party.adults !== Number(process.env.defaultAdultsCount)) {
    query.adults = party.adults.toString()
  } else {
    query.adults = undefined
  }
  if (party.childAges.length) {
    query.adults = party.adults.toString()
    query.child_ages = party.childAges.join(',')
    query.children = party.childAges.length.toString()
  } else {
    query.children = undefined
    query.child_ages = undefined
  }
  return query
}

export function fromQuery({ adults, child_ages }: Partial<QueryPart>): {
  party?: SearchParty
} {
  if (!adults && !child_ages) return { party: { adults: 2, childAges: [] } }
  let childAges: number[] = []
  if (child_ages && Array.isArray(child_ages)) {
    childAges = child_ages.map((age) => parseInt(age, 10))
  }
  if (child_ages && typeof child_ages === 'string') {
    childAges = childAgeCSVStringToNumberArray(child_ages)
  }
  return {
    party: {
      adults: typeof adults === 'undefined' ? 2 : Number(adults),
      childAges,
    },
  }
}
