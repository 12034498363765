import { CATEGORIES } from '~/apps/pu-links/simpleCategories'
import { isGlamping } from '~/utility/glamping'
import { SearchFilters } from '../../search/schemas'
import { UrlQuery } from '../domain/UrlQuery'
import { excludeQueryParams } from '../query-excluder/excludeQueryParams'
import { SearchFiltersUrl } from '../search-filters/SearchFiltersUrl'

type CustomQueryParams = Record<string, string | undefined>

export interface SearchLinkGeneratorInput {
  category?: string
  excludeType?: string
  facet?: string
  hierarchyPath?: string
  filters?: Partial<SearchFilters>
  customQueryParams?: CustomQueryParams
  name?: string
  params?: Record<string, any>
  query?: Record<string, any>
}

export class SearchLinkGenerator {
  private readonly defaultCategory = 'campsites'
  noCategories: boolean
  constructor(options: { noCategories?: boolean } = {}) {
    this.noCategories = options.noCategories ?? false
  }

  getUrlData({
    category,
    excludeType,
    facet,
    hierarchyPath,
    filters,
    customQueryParams,
    name,
    params,
    query,
  }: SearchLinkGeneratorInput) {
    if (name && params && query) {
      return {
        name,
        params,
        query: {
          ...query,
          ...(filters ? SearchFiltersUrl.filtersToUrl(filters).query : {}),
          ...customQueryParams,
        },
      }
    }

    const initialQuery = this.getUrlQuery({
      excludeType,
      filters,
      customQueryParams,
    })
    const filtersAfterExclusion = SearchFiltersUrl.urlToFilters(initialQuery)
    const cleanedFilters = this.cleanFilters(filtersAfterExclusion, category)
    const urlQuery = this.getUrlQuery({
      excludeType,
      filters: cleanedFilters,
      customQueryParams,
    })

    const path = this.getUrlPath({
      category: this.noCategories
        ? this.defaultCategory
        : this.getCategoryName(category, filtersAfterExclusion?.categoryIds),
      facet,
      hierarchyPath: hierarchyPath || filters?.hierarchyPath,
    })

    return { path, query: urlQuery }
  }

  private cleanFilters(filters?: SearchFilters, category?: string) {
    if (!filters) return {}
    if (!filters.categoryIds || this.noCategories) return filters
    if (this.isParamsRequiredInCategory(category)) return filters
    if (this.isSingularSearchCategory(filters)) {
      return {
        ...filters,
        categoryIds: [],
      }
    }
    return filters
  }

  private isSingularSearchCategory(filters: SearchFilters) {
    return filters.categoryIds!.length === 1 || isGlamping(filters.categoryIds)
  }

  private isParamsRequiredInCategory(category?: string) {
    return !category || this.defaultCategory !== category
  }

  private getCategoryName(searchCategory?: string, categories?: string[]) {
    if (searchCategory && this.defaultCategory !== searchCategory) {
      return searchCategory
    }
    if (categories && categories.length === 1) {
      return CATEGORIES[categories[0]]
    } else if (isGlamping(categories)) {
      return 'glamping'
    }
    return searchCategory
  }

  private getUrlPath({
    category,
    facet,
    hierarchyPath,
  }: Partial<{
    category: string
    facet: string
    hierarchyPath: string
    excludeType?: string
  }>) {
    return (
      this.getUrlCategory(category) +
      this.getUrlHierarchyPath(hierarchyPath) +
      this.getUrlFacet(facet)
    )
  }

  private getUrlCategory(category?: string) {
    const urlCategory = category || this.defaultCategory
    return `/${urlCategory}/`
  }

  private getUrlHierarchyPath(path?: string) {
    return path || ''
  }

  private getUrlFacet(facet?: string) {
    return facet ? `-/${facet}/` : ''
  }

  private getUrlQuery({
    excludeType,
    filters,
    customQueryParams,
  }: {
    excludeType?: string
    filters?: SearchFilters
    customQueryParams?: CustomQueryParams
  }): UrlQuery {
    const query = {
      ...(filters ? SearchFiltersUrl.filtersToUrl(filters).query : {}),
      ...customQueryParams,
    }
    const forceQueryParams = customQueryParams
      ? Object.keys(customQueryParams)
      : undefined

    return excludeQueryParams({ excludeType, forceQueryParams, query })
  }
}
