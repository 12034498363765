import type { UserDetailsMachineInterpreter } from './machine'

export async function getUserDetailService() {
  const { userDetailsContainer } = await import(
    /* webpackChunkName: "user-details-inversify", webpackMode: "lazy" */ './inversify.config'
  )
  return userDetailsContainer.get<UserDetailsMachineInterpreter>(
    'UserDetailsMachineInterpreter',
  )
}
