// tslint:disable no-identical-functions
import { SearchFilters } from '../../search/schemas'

interface QueryPart {
  hierarchyPath?: string
}

export function toQuery({ hierarchyPath }: SearchFilters): QueryPart | {} {
  return {
    // to prevent empty string. Maybe we should introduce a sanitization
    hierarchyPath: hierarchyPath || undefined,
  }
}

export function fromQuery({ hierarchyPath }: QueryPart): {
  hierarchyPath?: string
} {
  return {
    hierarchyPath: hierarchyPath || undefined,
  }
}
