import { SearchFilters } from '../../search/schemas'
import {
  GeoCircle,
  getGeoCircleFromPointAndRange,
  isValidLatLng,
  isValidRange,
} from '~/utility/geo/GeoLocation'

interface QueryPart {
  lat?: string
  lng?: string
  within: number
}

export function toQuery({ area, within }: SearchFilters): QueryPart | {} {
  if (!area) return within ? { within } : {}

  const { lat, lng, rangeMiles } = area

  if (within) {
    return area.isEmptyPoint ? { within } : { lat, lng, within }
  }

  if (area.isEmptyPoint) {
    return {
      within: area.rangeMiles,
    }
  }

  return {
    lat,
    lng,
    within: rangeMiles,
  }
}

export function fromQuery(query: Partial<QueryPart>): { area?: GeoCircle } {
  if (query.lat && query.lng) {
    const point = {
      lat: Number(query.lat),
      lng: Number(query.lng),
      isEmptyPoint: false,
    }
    const range = Number(
      query.within || parseInt(process.env.DEFAULT_WITHIN_RANGE || '15'),
    )
    if (isNaN(range)) return {}
    if (
      isValidLatLng({ lat: point.lat, lng: point.lng }) &&
      isValidRange(range)
    ) {
      return { area: getGeoCircleFromPointAndRange(point, range) }
    }
  } else if (query.within && !isNaN(query.within)) {
    return {
      area: {
        lat: 0,
        lng: 0,
        isEmptyPoint: true,
        rangeMiles: Number(query.within),
      },
    }
  }
  return {}
}
