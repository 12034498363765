import { getExclusionList } from './getExclusionList'

export function excludeQueryParams({
  excludeType,
  forceQueryParams,
  query,
}: {
  excludeType?: string
  forceQueryParams?: string[]
  query: Record<string, any>
}): Record<string, any> {
  const outQuery = { ...query }
  const exclusionList = getExclusionList(excludeType).concat(
    getExclusionList('alwaysExcluded'),
  )

  exclusionList.forEach((key) => {
    if (!forceQueryParams?.includes(key)) {
      delete outQuery[key]
    }
  })
  return outQuery
}
