import { SearchFilters } from '../../search/schemas'
import { SearchLinkGenerator } from './SearchLinkGenerator'

export class HomepageLinkGenerator extends SearchLinkGenerator {
  getUrlData(filters: SearchFilters) {
    const urlData = super.getUrlData({
      filters,
      excludeType: 'homepage',
    })
    return {
      ...urlData,
      path: '/',
    }
  }
}
