import { getDefaultSearchFilters } from '../../search/defaults'
import type { SearchFilters } from '../../search/schemas'
import { UrlQuery } from '../domain/UrlQuery'
import { urlParts } from '../parts/urlParts'

function isObject(value: any): value is Object {
  return value && typeof value === 'object' && !Array.isArray(value)
}

function deepSortObject(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(deepSortObject).sort()
  } else if (isObject(obj)) {
    const sortedKeys = Object.keys(obj).sort()
    const result: Record<string, any> = {}
    for (const key of sortedKeys) {
      result[key] = deepSortObject(obj[key])
    }
    return result
  }
  return obj
}

function urlToFilters(query: UrlQuery): SearchFilters {
  return deepSortObject(doUrlToFilters(query))
}

function filtersToUrl(filtersRaw: SearchFilters) {
  const query: UrlQuery = Object.assign(
    {},
    ...urlParts.map((part) => part.toQuery(filtersRaw)),
  )
  const params = {}

  return {
    query,
    params,
    route: {
      name: 'search',
      query: query as { [x: string]: any },
    },
  }
}

function doUrlToFilters(query: UrlQuery): SearchFilters {
  return Object.assign(
    {},
    getDefaultSearchFilters(),
    ...urlParts.map((part) => part.fromQuery(query)),
  )
}

// tslint:disable-next-line variable-name
export const SearchFiltersUrl = { urlToFilters, filtersToUrl }
