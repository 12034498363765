import { rootContainer } from '~/container'
import sentryCapture from '~/utility/sentryCapture'
import { exclusions } from './exclusions'

const defaultExclusion = 'none'
export function getExclusionList(excludeType?: string): string[] {
  const excludeTypeDefined = excludeType || defaultExclusion
  if (exclusions[excludeTypeDefined]) {
    return exclusions[excludeTypeDefined]
  } else {
    sentryCapture(
      new TypeError(`Unknown query exclusion type: '${excludeTypeDefined}'`),
      rootContainer,
    )
    return exclusions[defaultExclusion]
  }
}
