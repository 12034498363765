export abstract class NextBookingsRepository {
  abstract getNext(langCode: string): Promise<NextBooking | null>
}

export interface NextBooking {
  arrive: string
  campsiteName: string
  depart: string
  prettyId: string
}
