import { assign, createMachine, Interpreter } from 'xstate'
import { NextBooking } from './types'

interface NextBookingContext {
  booking: NextBooking | null
}

export function returnNextBookingMachine() {
  return createMachine<NextBookingContext>(
    {
      initial: 'fetching',
      context: { booking: null },
      states: {
        fetching: {
          invoke: {
            src: 'getNextBooking',
            onDone: {
              target: 'final',
              actions: assign({
                booking: (_, { data }) => data,
              }),
            },
            onError: {
              target: 'final',
            },
          },
        },
        final: { type: 'final' },
      },
    },
    {
      services: {
        getNextBooking: () => {
          throw new Error('not implemented')
        },
      },
    },
  )
}

export type NextBookingMachineInterpreter = Interpreter<NextBookingContext>
