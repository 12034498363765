import { LatLng } from '~/utility/geo/GeoLocation'
import { safeJSONParse } from '../../../utility/json'
import { SearchFilters } from '../../search/schemas'

interface QueryPart {
  bounds?: string
}

export function toQuery({ bounds }: SearchFilters): QueryPart | {} {
  return {
    bounds: bounds ? JSON.stringify(bounds) : undefined,
  }
}

function convertToNumberCoords(coords: LatLng): LatLng {
  return {
    lat: typeof coords.lat === 'string' ? Number(coords.lat) : coords.lat,
    lng: typeof coords.lng === 'string' ? Number(coords.lng) : coords.lng,
  }
}

export function fromQuery({ bounds }: QueryPart): {
  bounds?: { northEast: LatLng; southWest: LatLng }
} {
  if (!bounds) return { bounds: undefined }

  const parsedBounds = safeJSONParse(bounds) as {
    northEast: LatLng
    southWest: LatLng
  }
  if (!parsedBounds || typeof parsedBounds !== 'object')
    return { bounds: undefined }

  const { northEast, southWest } = parsedBounds
  if (!northEast || !southWest) return { bounds: undefined }

  return {
    bounds: {
      northEast: convertToNumberCoords(northEast),
      southWest: convertToNumberCoords(southWest),
    },
  }
}
